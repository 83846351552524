import './main.scss';
import objectFitImages from 'object-fit-images';

// import $ from 'jquery';
import siteHeader from './components/site-header/site-header';
//import interactiveMap from './components/interactiveMap/interactiveMap';

document.addEventListener('DOMContentLoaded', () => {
  console.log('DOM fully loaded and parsed');

//   jarallax(document.querySelectorAll('.jarallax'), {
//     speed: 0.2
// });
  objectFitImages( null, {watchMQ: true} );
  siteHeader();
  //interactiveMap(window.wherewework_state);
});

function getComponents() {
  //Look for interactive map dom element,
  if (document.getElementById('interactiveMap')) {
    import(/* webpackChunkName: "interactiveMap" */ './components/interactiveMap/interactiveMap')
      .then(module => {
        module.default(window.interactive_map_state);
      })
      .catch(error => 'An error occurred while loading the interactive map component');
  }

  //Look for video overlay dom element
  if (document.getElementById('videoOverlayContainer')) {
    import(/* webpackChunkName: "videoOverlay" */ './components/lightbox/video-overlay')
      .then(module => {
        module.default();
      })
      .catch(error => 'An error occurred while loading the video overlay component');
  }

  //Look for video overlay dom element
  // if (document.getElementById('videoOverlayContainer')) {
  //   import(/* webpackChunkName: "videoOverlay" */ './components/lightbox/video-overlay')
  //     .then(module => {
  //       module.default();
  //     })
  //     .catch(error => 'An error occurred while loading the video overlay component');
  // }

}

getComponents();


// WP Popups events
jQuery(document).ready(function() {
  //Make sure there's a datalauer to set pramas to
  window.dataLayer = window.dataLayer || [];
  //Send GA event when form is successfully submitted
  jQuery(document).bind("gform_confirmation_loaded", function(event, formId) {
    window.dataLayer.push({
      'event': 'lightbox-newsletter-signup',
    });
    setTimeout(() => {
      window.wppopups.togglePopups(false, false);
    }, 2000);
  });

  //Set focus on first input for accessibility
  jQuery(document).bind("wppopups.popup_opened", function(event, data) {
    event.target.querySelectorAll('input')[0].focus();
  });

  // Send GA event when lightbox is closed
  jQuery('.spu-close-popup, .spu-bg').on('click', (e) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'lightbox-close'
    });
  })

  jQuery(document).bind("wppopups.popup_closed", function(event, data) {
    console.log('Popup closed', event, data);
  });
});